









































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import ContentfulService from "../../Scripts/Common/contentful-service";

@Component
export default class w3 extends Vue {
  public page: any = null;
  public contentful = new ContentfulService();

  mounted() {
    //6beq10CTpx80bI4p71ghBq
    this.loadPage();
  }

  public async loadPage() {
    await this.contentful.GetPage("6beq10CTpx80bI4p71ghBq").then((res) => {
      this.page = res.items[0].fields;
    });
    ///console.log(this.page);
  }
}
