































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
// import Locations from "./Location/locationX.vue";
import Locations from "../../Common/Cards/Location/locationXAnimation.vue";
import ContentfulService from "../../../Scripts/Common/contentful-service";

@Component({
  components: {
    Locations,
  },
})
export default class locationsAvailable extends Vue {
  public contentful = new ContentfulService();
  public page: any = null;
  public miscOffset: number[] =  [0, 350, 500]

  mounted() {
    this.loadPage();
  }

  public async loadPage() {
    await this.contentful.GetPage("4TW99StlPZclkKaX4nKVbV").then((res) => {
      this.page = res.items[0].fields;
    });
    //console.log(this.page.cardContainer[0].fields);
  }
}
