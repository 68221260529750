import Vue from 'vue'
import App from './App.vue'

import AOS from 'aos'
import 'aos/dist/aos.css'

import ReadMore from 'vue-read-more';

Vue.config.productionTip = false;
Vue.use(ReadMore);

new Vue({
  created () {
    AOS.init()
},
  render: h => h(App),
}).$mount('#app')


