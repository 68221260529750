























































































































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  components: {},
})
export default class SocialHorizontalBar extends Vue {
  async mounted() {}
}
