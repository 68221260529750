


























import Vue from "vue";
import Component from "vue-class-component";

import ContentfulService from "../../../Scripts/Common/contentful-service";
import ContactUS from "../../Common/ContactUs/KatesContactUs.vue";
import SocialHorizontalBar from "../../Common/SocialMedia/SocialHorizontalBar.vue"
@Component({
  components: {
    ContactUS,
    SocialHorizontalBar
  },
})
export default class contactUs extends Vue {
  public contentful = new ContentfulService();
  public page: any = null;

  mounted() {
    this.loadPage();
  }

  public async loadPage() {
    await this.contentful.GetPage("4Jcu7rnaTyobrNfWLxWXWg").then((res) => {
      this.page = res.items[0].fields;
    });
  }
}
