
































































































import Vue from "vue";
import Component from "vue-class-component";

import ContentfulService from "../../../Scripts/Common/contentful-service";

@Component
export default class aboutSection extends Vue {
  public contentful = new ContentfulService();
  public page: any = null;

  mounted() {
    this.loadPage();
  }

  public async loadPage() {
    await this.contentful.GetPage("4KoJTrTx90riGwLLF859lU").then((res) => {
      this.page = res.items[0].fields;
    });
  }

}
