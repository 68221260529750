














import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
})
export default class serviceX extends Vue {
  @Prop() public index: number;
  @Prop() public cardTitle: string;
  @Prop() public cardPlainText: string;
  @Prop() public imageUrl: string;

  mounted() {
    var imageContainer = document.getElementById(
      "serviceCardImage" + this.index
    );

    //var imageDiv = document.createElement("div");
    var imageFile = document.createElement("img");
    imageFile.classList.add("serviceImage");
    imageFile.src = this.imageUrl;
    imageFile.style.height = "100px";
    imageFile.style.width = "100px";
    //imageDiv.appendChild(imageFile);
    imageContainer.prepend(imageFile);
  }
}
