




















import Vue from "vue";
import Component from "vue-class-component";

import SocialSideBar from "../components/Common/SocialMedia/SocialSideBar.vue"
import Nav from "./NavBars/fixedAnimated.vue";
//import Banner from './Banners/teddy.vue';
//import Banner from './Common/Cards/locationXAnimation.vue';
import Banner from "./Banners/w3.vue";
import Wrapper from "./Content/wrapper1.vue";
import ContentfulService from "../Scripts/Common/contentful-service";

@Component({
  components: {
    SocialSideBar,
    Nav,
    Banner,
    Wrapper,
  },
})
export default class container extends Vue {
  async mounted() {
     const contentful = new ContentfulService();
  }
}
