















































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class fixedAnimated extends Vue {
  public navbar: HTMLElement = document.getElementById("navbar") as HTMLElement;
  public sticky: number = 0;

  public mounted() {
    this.navbar = document.getElementById("navbar") as HTMLElement;
    // Get the offset position of the navbar
    this.sticky = this.navbar.offsetTop;
  }

  public created() {
    window.addEventListener("scroll", this.handleScroll);
  }

  public destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  private handleScroll(event: Event) {
    // Any code to be executed when the window is scrolled
    if (window.pageYOffset >= this.sticky) {
      this.navbar.classList.add("sticky");
    } else {
      this.navbar.classList.remove("sticky");
    }
  }
}
