









































import Vue from "vue";
import Component from "vue-class-component";
import Testimonial from "../../Common/Cards/Testimonial/testimonial.vue";

import ContentfulService from "../../../Scripts/Common/contentful-service";

@Component({
  components: {
    Testimonial,
  },
})
export default class testimonials extends Vue {
  public contentful = new ContentfulService();
  public page: any = null;
  public cardContainer: any = null;
  public miscOffset: number[] =  [0, 350, 500]

  mounted() {
    this.loadPage();
  }

  public async loadPage() {
    await this.contentful.GetPage("1mHydTR2UnbVEmxZ9tiiqO").then((res) => {
      this.page = res.items[0].fields;
    });
    this.cardContainer = this.page.cardContainer;
  }
}
