

















import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class testimonials extends Vue {
  @Prop() public index: number;
  @Prop() public cardTitle: string;
  @Prop() public cardSubtitle: string;
  @Prop() public cardPlainText: string;
  @Prop() public imageUrl: string;

  mounted() {}
}
