



































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VueMarkdown from "vue-markdown";
import { ContactUsFormModel } from "./models/contactUsForm";

import axios from "axios";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class ContactUsTemplate extends Vue {
  public model: ContactUsFormModel = null;
  public regexp = new RegExp(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

  public mounted() {
    this.model = new ContactUsFormModel();
    //  debugger
    //console.log(this.model);
  }

  @Watch("model.email.value")
  public nameChanged(newVal: string) {
    if (newVal != "") {
      this.validate("email", newVal);
    }
  }

  private submit() {
    axios({
      url: "https://formspree.io/f/mknplyed",
      method: "post",
      headers: {
        Accept: "application/json",
      },
      data: {
        name: this.model.name,
        from: this.model.email.value,
        _subject: `${this.model.subject}`,
        message: this.model.message.text,
      },
    })
      .then((response) => {
        this.model.name = "";
        this.model.email.value = "";
        this.model.message.text = "";
        this.model.subject = "";
        //he redirects app to '/success' route once payload completed.
        //this.$router.push({ path: '/success' });
        this.model.submitted = true;
      })
      .catch((error) => {
        if (error.response) {
          // eslint-disable-next-line no-alert          alert(error.response.data); // => the response payload        }      });
        }
      });
  }

  // validate by type and value
  private validate(type, value) {
    if (type === "email") {
      this.model.email.valid = this.isEmail(value) ? true : false;
    }
  }

  // check for valid email adress
  private isEmail(value): boolean {
    return this.regexp.test(value);
  }
}
