import { render, staticRenderFns } from "./contactUs.vue?vue&type=template&id=2968aa35&scoped=true&"
import script from "./contactUs.vue?vue&type=script&lang=ts&"
export * from "./contactUs.vue?vue&type=script&lang=ts&"
import style0 from "./contactUs.vue?vue&type=style&index=0&id=2968aa35&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2968aa35",
  null
  
)

export default component.exports