export class ContactUsFormModel{
    name?:string;
    email: Email;
    subject: string;
    // features?: [] = [];
    // selection: Selection;
    message: Message;
    submitted?: boolean;

    public constructor(){
        this.email = new Email();
        // this.selection = new Selection();
        this.message = new Message();
    }
}

export class Email{
    valid: boolean;
    value: string;

    constructor(){
        this.valid = true;
        this.value = '';
    }
}

export class Selection{
    member?: string;
    framework?: string;
    features?: string[];

    public constructor(){
        this.member = '';
        this.framework = '';
        this.features = ["Invisaline", "Other", "Service Type #3"];
    }
}

export class Message{
    text: string;
    maxLength:number;

    public constructor(){
        this.text = '';
        this.maxLength = 255;
    }
}