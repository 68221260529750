import { createClient, ContentfulClientApi } from "contentful";
/**
 * Common methods.
 */
export default class ContentfulService {
    
    private contentfulClient: ContentfulClientApi;
    public contentful = require("contentful");

    public static page: any = null;

   constructor(){
        this.contentfulClient = createClient({
            space: "jaa4c4to2rzl",
            accessToken: "NgAfbSxUvkY0J4I6fe2aBb8R6LjysTQ-3KFxCdXuS5I",
          });
    }

         /**
     * Retrieve a specific Page from contentful.
     * @param pageId
     */
    public async GetPage(pageId: string): Promise<any> {
      let response = await this.contentfulClient.getEntries({'sys.id': pageId, 'include': '10'});
      
      if (response && response.items.length > 0) {
          return response;
      }
      else {
          return null;
      }
  }    
}