







































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import VueMarkdown from "vue-markdown";

@Component({
  components: {
    VueMarkdown,
  },
})
export default class locationXAnimation extends Vue {
  @Prop() public index: number;
  @Prop() public cardTitle: string;
  @Prop() public cardPlainText: string;
  @Prop() public imageUrl: string;
  @Prop() public locationWebsiteUrl: string;

  public mounted() {
    //______________________________________________________________________________________________________
    // Set The Location thumbnail
    //______________________________________________________________________________________________________
    var imageContainer = document.getElementById("aniCardImg" + this.index);
    var imageFile = document.createElement("img");
    imageFile.classList;
    imageContainer.prepend(imageFile);
    //______________________________________________________________________________________________________
    // if hanham dental set background colour
    //______________________________________________________________________________________________________
    if (this.index == 0) {
      imageContainer.style.backgroundColor = "#5c4e71";
    }
    if (this.index == 1) {
      //imageContainer.style.padding = "4px";
    }
  }
}
