



































































































































import Vue from "vue";
import Component from "vue-class-component";
import _ from "lodash";
import ContentfulService from "../../../Scripts/Common/contentful-service";

@Component({
  components: {},
})
export default class TestimonialCarousel extends Vue {
  public images: Array<string> = new Array<string>();
  public timer: number = null;
  public currentIndex: number = 0;
  public contentful = new ContentfulService();
  public page: any = null;
  public cardContainer: any = null;

  mounted() {
    this.loadPage();
    this.startSlide();
  }

  public async loadPage() {
    await this.contentful.GetPage("1mHydTR2UnbVEmxZ9tiiqO").then((res) => {
      this.page = res.items[0].fields;
    });
    this.cardContainer = this.page.cardContainer;
    this.cardContainer.forEach((element) => {
      this.images.push(
        element.fields.cardImage.fields.file.url// + "?w=350&h=345"
      );
    });
    console.log(this.cardContainer);
  }

  startSlide() {
    this.timer = setInterval(this.next, 8000);
  }

  next() {
    if (this.currentIndex == this.images.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex += 1;
    }
  }
  prev() {
    if (this.currentIndex == 0) {
      this.currentIndex = this.images.length - 1;
    } else {
      this.currentIndex -= 1;
    }
  }

  get currentImg() {
    return this.images[Math.abs(this.currentIndex) % this.images.length];
  }
}
