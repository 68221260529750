












// Packages
import { Component, Vue } from "vue-property-decorator";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

import Container from "./components/container.vue";

@Component({
  components: {
    Container,
  },
})
export default class App extends Vue {}
