










































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import Services from "../../Common/Cards/Services/serviceX.vue";

import ContentfulService from "../../../Scripts/Common/contentful-service";

@Component({
  components: {
    Services,
  },
})
export default class services extends Vue {
  public contentful = new ContentfulService();
  public page: any = null;
  public cardContainer: any = null;

  mounted() {
    this.loadPage();
  }

  public async loadPage() {
    await this.contentful.GetPage("15YxT4rIEiAkMPZhcpTDXr").then((res) => {
      this.page = res.items[0].fields;
    });
    this.cardContainer = this.page.cardContainer;
  }
}
