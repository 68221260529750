












import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
//------------------COMPONENTS--------------------------------
import Section1 from "./Sections/services.vue";
import AboutSection from "./Sections/aboutSection.vue";
import LocationsAvailable from "./Sections/locationsAvailable.vue";
// import CallToAction from "./Sections/callToAction.vue";
import Testimonials from "./Sections/testimonials.vue";
import TestimonialCarousel from "./Sections/TestimonialCarousel.vue";
import ContactUs from "./Sections/contactUs.vue";
import SectionFooter from "./Sections/sectionFooter.vue";
//----------------Services-----------------------------------------
import ContentfulService from "../../Scripts/Common/contentful-service";
@Component({
  components: {
    Section1,
    AboutSection,
    LocationsAvailable,
    //CallToAction,
    Testimonials,
    TestimonialCarousel,
    ContactUs,
    SectionFooter,
  },
})
export default class wrapper1 extends Vue {
  public page: any = null;

  mounted() {
    //this.page = contentfulHelper.getPage()
    //debugger;
    //console.wr
  }
}
