








































































































































































































































import Vue from "vue";
import Component from "vue-class-component";

@Component
export default class sectionFooter extends Vue {}
